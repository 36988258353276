<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-2">
                <h5 class="mb-0">Subastas</h5>
              </div>
              <div class="col-sm-10">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Comercio</li>
                  <li class="breadcrumb-item active">Gestión Subastas</li>
                  <li class="breadcrumb-item active">Configuración</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 border-0">
                <div class="col-3 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Subastas</h5>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Productos"
                      data-toggle="tab"
                      href="#Productos"
                      v-if="
                        id &&
                        ($store.getters.can('com.subastas.crearProducto') ||
                          $store.getters.can('com.subastas.editarProducto') ||
                          $store.getters.can('com.subastas.eliminarProducto'))
                      "
                    >
                      Productos
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Clientes"
                      data-toggle="tab"
                      href="#Clientes"
                      v-if="
                        id &&
                        ($store.getters.can('com.subastas.asignarClientes') ||
                          $store.getters.can('com.subastas.eliminarClientes'))
                      "
                    >
                      Clientes
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-2">
                          <label>Tipo Contrato</label>
                          <select
                            v-model="form.tipo_contrato"
                            class="form-control form-control-sm"
                            :class="
                              $v.form.tipo_contrato.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="form.estado == 2"
                          >
                            <option value="">Seleccione...</option>
                            <option value="1">Subasta</option>
                            <option value="2">Venta Directa</option>
                            <option value="3">Venta En Destino</option>
                          </select>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Nombre</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.nombre"
                            :class="
                              $v.form.nombre.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="form.estado == 2"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label>Fecha Inicial</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="form.fecha_ini"
                            :class="
                              $v.form.fecha_ini.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="validaFechas"
                            :disabled="form.estado == 2"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label>Fecha Final</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="form.fecha_fin"
                            :class="
                              $v.form.fecha_fin.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="validaFechas"
                            :disabled="form.estado == 2"
                          />
                        </div>
                        <div
                          class="form-group col-md-3"
                          v-if="form.tipo_contrato == 1"
                        >
                          <label>Fecha Máxima Oferta</label>
                          <div class="row">
                            <div class="col-md-8">
                              <input
                                type="date"
                                v-model="form.fecha_max_oferta_fecha"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.fecha_max_oferta_fecha.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @input="validaFechas"
                                :disabled="form.estado == 2"
                              />
                            </div>
                            <div class="col-md-4">
                              <input
                                type="text"
                                v-model="form.fecha_max_oferta_hora"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.fecha_max_oferta_hora.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                v-mask="{
                                  mask: '99:99',
                                  hourFormat: '24',
                                }"
                                @input="validaFechas"
                                :disabled="form.estado == 2"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-2">
                          <label>Estado</label>
                          <select
                            v-model="form.estado"
                            class="form-control form-control-sm"
                            :class="
                              $v.form.estado.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              !id ||
                              (subasta &&
                                (subasta.clientes.length == 0 ||
                                  subasta.productos.length == 0 ||
                                  subasta.estado == 2))
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="Productos"
                    v-if="
                      id &&
                      ($store.getters.can('com.subastas.crearProducto') ||
                        $store.getters.can('com.subastas.editarProducto') ||
                        $store.getters.can('com.subastas.eliminarProducto'))
                    "
                  >
                    <SubastaProducto ref="SubastaProducto" />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="Clientes"
                    v-if="
                      id &&
                      ($store.getters.can('com.subastas.asignarClientes') ||
                        $store.getters.can('com.subastas.eliminarClientes'))
                    "
                  >
                    <SubastaCliente ref="SubastaCliente" />
                  </div>
                  <!--   <div
                    v-if="
                      subasta &&
                      subasta.productos.length > 0 &&
                      subasta.clientes.length > 0
                    "
                  >
                    <div
                      class="card m-3"
                      v-for="(prod, indicePro) in productsAgroups"
                      :key="prod.id"
                      :class="getCardColorCl(indicePro)"
                    >
                      <div class="card-header p-2 pl-4">
                        <h4 class="card-title w-100">
                          <a class="d-block w-100 collapsed">
                            <i class="fas fa-coins fa-lg"> </i>
                            {{ prod[0].producto.nombre }}
                          </a>
                        </h4>
                      </div>
                      <div class="card-body table-responsive">
                        <div class="row">
                          <div
                            class="card mb-2 col-md-12"
                            v-for="product /* indiProduct */ in productsAgroups[
                              indicePro
                            ]"
                            :key="product.id"
                          >
                            <div class="card-header">
                              <i class="fas fa-calendar"></i>
                              {{ product.fecha_ini }} / {{ product.fecha_fin }}
                              <b> | </b> {{ product.cant_vh }} Ctks/D ({{
                                product.volumen
                              }}
                              BD)
                            </div>
                            <div class="card-body">
                              <div
                                class="card mb-2 col-md-12"
                                v-for="client in subasta.clientes"
                                :key="client.id"
                              >
                                <div class="card-header">
                                  <div class="row">
                                    <div class="col-md-5 d-flex">
                                      <i class="fas fa-user-tie fa-lg mr-1"></i>
                                      <b> Cliente: </b
                                      >{{ client.cliente.razon_social
                                      }}<b class="ml-2 mr-2"> | </b>
                                      <b> Valor: </b>
                                      <i
                                        class="fas fa-dollar-sign fa-lg ml-2 mr-2"
                                      ></i>
                                      <input
                                        type="number"
                                        v-model="form.precio_oferta"
                                        class="form-control form-control-sm p-2 col-md-4"
                                      />
                                    </div>
                                    <div class="col-md-2 btn-group">
                                      <b> Cantidad: </b>
                                        <input
                                        type="checkbox"
                                        class="form-control p-0"
                                        value="1"
                                      /> 
                                    </div>
                                  </div>
                                </div>
                                <div class="card-header">
                                  <b
                                    style="font-style: italic; font-size: 12px"
                                  >
                                    Precio Oferta: Brent{{
                                      product.precio_piso != 0
                                        ? product.nSigno +
                                          " " +
                                          product.precio_piso +
                                          " " +
                                          product.nMoneda
                                        : product.nSigno + " " + "Oferta"
                                    }}</b
                                  >
                                </div>
                                <div class="card-body table-responsive">
                                  <table
                                    class="table table-hover table-sm table-striped"
                                  >
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th></th>
                                        <th
                                          v-for="fecha in fechasEnRango"
                                          :key="fecha"
                                          class="text-center"
                                        >
                                          {{ fecha }}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td class="text-bold"><br />Ctks/D</td>
                                        <td class="text-bold">Adjudicado</td>
                                        <td
                                          v-for="fecha in fechasEnRango"
                                          :key="fecha"
                                          class="text-center"
                                        >
                                          <div
                                            class="d-flex justify-content-center"
                                          >
                                            <input
                                              type="text"
                                              class="form-control form-control-sm col-md-6 text-center"
                                              v-model="form.cant_vehi"
                                            />
                                          </div>
                                          <div
                                            class="d-flex justify-content-center"
                                          >
                                            <input
                                              type="text"
                                              class="form-control form-control-sm col-md-6 text-center mt-2"
                                             
                                            />
                                          </div> 
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="$store.getters.can('com.subastas.edit')"
                  >
                    <i class="fas fa-paper-plane"></i>
                    <br />Guardar
                  </button>

                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i>
                    <br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { required, minLength, helpers } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import SubastaProducto from "./SubastaProducto";
import SubastaCliente from "./SubastaCliente";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);

export default {
  name: "SubastaForm",
  components: {
    Loading,
    SubastaProducto,
    SubastaCliente,
  },

  data() {
    return {
      cargando: false,
      /* productsAgroups: null, */
      id: null,
      subasta: null,
      accion: null,
      metodo: null,
      form: {},
      listasForms: {
        estados: [],
        tipo_contratos: [],
      },
    };
  },
  validations() {
    let fmf = {};
    let fmh = {};
    if (this.tipo_contrato == 1) {
      fmf = {
        required,
      };
      fmh = {
        required,
        timeFormat,
      };
    } else {
      fmf = {
        required: false,
      };
      fmh = {
        required: false,
      };
    }
    return {
      form: {
        tipo_contrato: {
          required,
        },
        nombre: {
          required,
          minLength: minLength(5),
        },
        fecha_ini: {
          required,
        },
        fecha_fin: {
          required,
        },
        fecha_max_oferta_fecha: fmf,
        fecha_max_oferta_hora: fmh,
        estado: {
          required,
        },
      },
    };
  },

  /* 
  computed: {
    fechasEnRango() {
      const fechas = [];
      const fechaActual = new Date(this.form.fecha_ini);

      // Convertir la fecha de inicio a un objeto Date
      const fechaFin = new Date(this.form.fecha_fin);

      // Iterar hasta que la fecha actual sea menor o igual a la fecha final
      while (fechaActual <= fechaFin) {
        fechas.push(new Date(fechaActual));

        // Incrementar la fecha actual
        fechaActual.setDate(fechaActual.getDate() + 1);
      }

      // Mapear las fechas al formato deseado 'YYYY-MM-DD'
      return fechas.map((fecha) => fecha.toISOString().split("T")[0]);
    },
  }, */

  methods: {
    async getIndex() {
      this.subasta = null;
      this.form = {
        estado: 1,
      };
      this.accion = this.$route.params.id ? "Editar" : "Crear";
      this.metodo = this.$route.params.id ? "POST" : "PUT";
      this.id = this.$route.params.id;

      if (this.id) {
        this.cargando = true;
        const me = this;
        axios
          .get("/api/com/subastas/" + this.id)
          .then((response) => {
            this.subasta = response.data;
            this.form = {
              id: this.subasta.id,
              nombre: this.subasta.nombre,
              fecha_ini: this.subasta.fecha_ini,
              fecha_fin: this.subasta.fecha_fin,
              fecha_max_oferta_fecha: moment(
                this.subasta.fecha_max_oferta
              ).format("YYYY-MM-DD"),
              fecha_max_oferta_hora: moment(
                this.subasta.fecha_max_oferta
              ).format("HH:mm:ss"),
              estado: this.subasta.estado,
              tipo_contrato: this.subasta.tipo_contrato,
            };
            /* if (this.subasta.productos.length > 0) {
              this.agruparProductos();
            } */
            this.$refs.SubastaProducto.getIndex();
            this.$refs.SubastaCliente.getIndex();
            me.cargando = false;
          })
          .catch(function (error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error:" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.cargando = false;
          });
      }
    },
    /* 
    agruparProductos() {
      const productsAgroups = this.subasta.productos.reduce((acc, sub) => {
        const productoId = sub.producto_liquido_id;

        if (!acc[productoId]) {
          acc[productoId] = [];
        }

        acc[productoId].push(sub);

        return acc;
      }, {});

      this.productsAgroups = Object.values(productsAgroups);
    },

    getCardColorCl(index) {
      const colors = [
        "card-navy",
        "card-secondary",
        "card-info",
        "card-warning",
      ];
      const colorIndex = index % colors.length;
      return colors[colorIndex];
    },
 */
    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    // Funciones de validaciones
    validaFechas() {
      let msg_error = "";
      // Se valida le fecha inicial y final
      if (this.form.fecha_ini && this.form.fecha_fin) {
        let fecha_menor = new Date(this.form.fecha_ini);
        let fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          msg_error += `<div><strong>La fecha inicial no puede ser mayor a la fecha final...</strong></div>`;
        }
      }

      // Se valida la fecha inicial con la fecha actual
      if (this.form.fecha_ini) {
        let fecha_ini_subasta = moment(this.form.fecha_ini).format(
          "YYYY-MM-DD"
        );
        let fecha_actual = moment().format("YYYY-MM-DD");

        if (fecha_ini_subasta <= fecha_actual) {
          this.form.fecha_ini = null;
          this.form.fecha_max_oferta_hora = null;
          msg_error += `<div><strong>La fecha inicio no puede ser menor a la fecha actual.</strong></div>`;
        }
      }

      // Se valida la fecha máxima de oferta con la fecha inicial
      if (
        this.form.fecha_ini &&
        this.form.fecha_max_oferta_fecha &&
        this.form.fecha_max_oferta_hora
      ) {
        const fecha_ini = new Date(`${this.form.fecha_ini} 00:00:00`).getTime();
        const fecha_max = new Date(
          `${this.form.fecha_max_oferta_fecha} ${this.form.fecha_max_oferta_hora}`
        ).getTime();

        const mlsDiff = 5 * 24 * 60 * 60 * 1000;
        const diff = fecha_ini - fecha_max;

        if (diff <= mlsDiff) {
          msg_error += `<div><strong>La fecha maxima de oferta debe ser maximo 5 días antes de la fecha inicial de la subasta</strong></div>`;
        }
      }
      // Si hay error se muestra
      if (msg_error) {
        this.$swal({
          icon: "error",
          html: msg_error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    // Funciones de acciones de los botones
    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        if (this.form.tipo_contrato == 1) {
          this.form.estado_proceso = 1;
          this.form.fecha_max_oferta = `${this.form.fecha_max_oferta_fecha} ${this.form.fecha_max_oferta_hora}`;
        } else {
          this.form.estado_proceso = 3;
          this.form.fecha_max_oferta = null;
        }

        axios({
          method: this.metodo,
          url: "/api/com/subastas",
          data: this.form,
        })
          .then((response) => {
            this.cargando = false;
            this.$route.params.id = response.data.id;
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "La subasta se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/Com/Subastas");
    },
  },

  beforeMount() {
    this.getEstados();
  },

  mounted() {
    this.getIndex();
  },
};
</script>
