<template>
  <div>
    <div class="card-body pb-0" v-if="$parent.subasta && $parent.subasta.estado == 1">
      <div class="row justify-content-center"
        v-if="
          $store.getters.can('com.subastas.asignarClientes') &&
          listasForms.clientes.length > 0
        "
      >
        <div class="form-group col-md-6">
          <v-select
            :class="[
              $v.form.cliente.$invalid ? 'is-invalid' : 'is-valid',
              $store.getters.getDarkMode ? 'dark-vselect' : '',
            ]"
            v-model="form.cliente"
            placeholder="Clientes"
            label="razon_social"
            class="form-control p-0"
            multiple
            :options="listasForms.clientes"
            :filterable="true"
          ></v-select>
        </div>
        <div class="form-group col-md-2">
          <button
            class="btn btn-primary"
            v-if="!$v.form.$invalid"
            @click="create()"
          >
            <i class="fas fa-plus"></i>
          </button>
          <button
            class="btn bg-navy"
            @click="seleccionarClientes()"
          >
            <i class="fas fa-list"></i>
          </button>
        </div>
      </div>
      <div v-else class="row justify-content-center">
        <div
          v-if="listasForms.clientes.length == 0"
          class="callout callout-warning text-muted col-md-6"
        >
          <h5 class="m-0">No hay clientes disponibles para ser asignados!</h5>
        </div>
      </div>
    </div>
    <div v-if="clientes" class="card-body">
      <div class="row justify-content-center">
        <div v-for="item in clientes" :key="item.id"
          class="col-lg-3 col-6 p-2 m-1"
        >
          <div class="small-box bg-lightblue mb-0">
            <div class="inner">
              <h3>{{ item.cliente.n_identificacion }}</h3>
              <p>{{ item.cliente.razon_social }}</p>
            </div>
            <div class="icon">
              <i class="fas fa-user-tie"></i>
            </div>
            <div class="small-box-footer">
              <div class="row justify-content-between">
                <div class="col-md-8 text-center">{{ item.cliente.email ? item.cliente.email : "Sin email de contacto" }}</div>
                <div class="col-md-4 text-center" v-if="$parent.subasta && $parent.subasta.estado == 1">
                  <a class="fas fa-minus-circle text-danger text-lg" @click="destroy(item.id)"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card-body">
      <div class="row justify-content-center">
        <div class="callout callout-warning text-muted m-0">
          <h5 class="m-0">No hay clientes asignados!</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "SubastaCliente",
  components: {
    vSelect,
  },
  data() {
    return {
      clientes: null,
      modal: {},
      form: {},
      listasForms: {
        clientes: [],
      },
    };
  },

  validations: {
    form: {
      cliente: {
        required,
      },
    },
  },

  methods: {
    getIndex() {
      this.clientes =
        this.$parent.subasta &&
        this.$parent.subasta.clientes &&
        this.$parent.subasta.clientes.length > 0
          ? this.$parent.subasta.clientes
          : null;
      this.clientes_id = this.clientes && this.clientes.length > 0
        ? this.clientes.map((cli) => cli.cliente_id)
        : [];
      this.getClientes();
    },

    // Funciones para llenado de las listas
    getClientes() {
      axios
        .get("/api/com/subastas/clientes/lista", {
          params: {
            not_in: this.clientes_id,
          },
        })
        .then((response) => {
          this.listasForms.clientes = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    seleccionarClientes(){
      let lista_clientes = "";
      this.listasForms.clientes.forEach((cli) =>{
        lista_clientes+= `<div>${cli.razon_social}</div>`
      });

      this.$swal({
        title: "Selección Clientes.",
        html: lista_clientes,
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Si, Agregar!",
      }).then((result) => {
        if (result.value) {
          this.form.cliente = this.listasForms.clientes;
          this.create();
        }
      });
    },

    // Funciones para las acciones de los formularios
    create() {
      const clientes_nuevos = this.form.cliente.map((form) => form.id);
      const clientes_actuales = this.clientes && this.clientes.length > 0
        ? this.clientes.map((cli) => cli.cliente_id)
        : [];
      this.clientes_id = clientes_nuevos.concat(clientes_actuales);
      this.save();
    },

    // Acciones de los botones
    save() {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/com/subastas/clientes",
        data: {
          com_subasta_id: this.$parent.id,
          clientes_id: this.clientes_id,
        },
      })
        .then(() => {
          this.$parent.cargando = false;
          this.form = {};
          this.$parent.getIndex();
          this.$swal({
            icon: "success",
            title: "Los clientes se actualizaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$parent.cargando = false;
          this.getIndex();
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    destroy(id) {
      this.$parent.cargando = true;
      let me = this;
      axios
        .delete("/api/com/subastas/clientes/" + id)
        .then(() => {
          this.$parent.getIndex();
          this.$swal({
            icon: "success",
            title: "Se retiró el Cliente exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        })
        .catch(function (error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          me.$parent.cargando = false;
        });
    },
  },
};
</script>
