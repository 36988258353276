var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-10"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Comercialización")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Comercio")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Gestión Subastas")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Configuración")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pt-2 pb-2 border-0"},[_c('div',{staticClass:"col-3 pt-2 pb-2"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Formulario "+_vm._s(_vm.accion)+" Subastas")])])]),_c('div',{staticClass:"card-body p-0"},[_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_vm._m(1),_c('li',{staticClass:"nav-item"},[(
                      _vm.id &&
                      (_vm.$store.getters.can('com.subastas.crearProducto') ||
                        _vm.$store.getters.can('com.subastas.editarProducto') ||
                        _vm.$store.getters.can('com.subastas.eliminarProducto'))
                    )?_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Productos","data-toggle":"tab","href":"#Productos"}},[_vm._v(" Productos ")]):_vm._e()]),_c('li',{staticClass:"nav-item"},[(
                      _vm.id &&
                      (_vm.$store.getters.can('com.subastas.asignarClientes') ||
                        _vm.$store.getters.can('com.subastas.eliminarClientes'))
                    )?_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Clientes","data-toggle":"tab","href":"#Clientes"}},[_vm._v(" Clientes ")]):_vm._e()])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Principal"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-2"},[_c('label',[_vm._v("Tipo Contrato")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_contrato),expression:"form.tipo_contrato"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_contrato.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"disabled":_vm.form.estado == 2},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_contrato", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Subasta")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Venta Directa")]),_c('option',{attrs:{"value":"3"}},[_vm._v("Venta En Destino")])])]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nombre),expression:"form.nombre"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.nombre.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text","disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.nombre)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nombre", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',[_vm._v("Fecha Inicial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_ini),expression:"form.fecha_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_ini.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"date","disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.fecha_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_ini", $event.target.value)},_vm.validaFechas]}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',[_vm._v("Fecha Final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_fin),expression:"form.fecha_fin"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_fin.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"date","disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.fecha_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_fin", $event.target.value)},_vm.validaFechas]}})]),(_vm.form.tipo_contrato == 1)?_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Fecha Máxima Oferta")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_max_oferta_fecha),expression:"form.fecha_max_oferta_fecha"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_max_oferta_fecha.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',attrs:{"type":"date","disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.fecha_max_oferta_fecha)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_max_oferta_fecha", $event.target.value)},_vm.validaFechas]}})]),_c('div',{staticClass:"col-md-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_max_oferta_hora),expression:"form.fecha_max_oferta_hora"},{name:"mask",rawName:"v-mask",value:({
                                mask: '99:99',
                                hourFormat: '24',
                              }),expression:"{\n                                mask: '99:99',\n                                hourFormat: '24',\n                              }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_max_oferta_hora.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',attrs:{"type":"text","disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.fecha_max_oferta_hora)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_max_oferta_hora", $event.target.value)},_vm.validaFechas]}})])])]):_vm._e(),_c('div',{staticClass:"form-group col-md-2"},[_c('label',[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.estado),expression:"form.estado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.estado.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"disabled":!_vm.id ||
                            (_vm.subasta &&
                              (_vm.subasta.clientes.length == 0 ||
                                _vm.subasta.productos.length == 0 ||
                                _vm.subasta.estado == 2))},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.estados),function(estado){return _c('option',{key:estado.numeracion,domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])})],2)])])])]),(
                    _vm.id &&
                    (_vm.$store.getters.can('com.subastas.crearProducto') ||
                      _vm.$store.getters.can('com.subastas.editarProducto') ||
                      _vm.$store.getters.can('com.subastas.eliminarProducto'))
                  )?_c('div',{staticClass:"tab-pane fade",attrs:{"id":"Productos"}},[_c('SubastaProducto',{ref:"SubastaProducto"})],1):_vm._e(),(
                    _vm.id &&
                    (_vm.$store.getters.can('com.subastas.asignarClientes') ||
                      _vm.$store.getters.can('com.subastas.eliminarClientes'))
                  )?_c('div',{staticClass:"tab-pane fade",attrs:{"id":"Clientes"}},[_c('SubastaCliente',{ref:"SubastaCliente"})],1):_vm._e()])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[(_vm.$store.getters.can('com.subastas.edit'))?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid),expression:"!$v.form.$invalid"}],staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]):_vm._e(),_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")])])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-2"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Subastas")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Principal"}},[_vm._v("Principal")])])
}]

export { render, staticRenderFns }