var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$parent.subasta && _vm.$parent.subasta.estado == 1)?_c('div',{staticClass:"card-body pb-0"},[(
        _vm.$store.getters.can('com.subastas.asignarClientes') &&
        _vm.listasForms.clientes.length > 0
      )?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"form-group col-md-6"},[_c('v-select',{staticClass:"form-control p-0",class:[
            _vm.$v.form.cliente.$invalid ? 'is-invalid' : 'is-valid',
            _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
          ],attrs:{"placeholder":"Clientes","label":"razon_social","multiple":"","options":_vm.listasForms.clientes,"filterable":true},model:{value:(_vm.form.cliente),callback:function ($$v) {_vm.$set(_vm.form, "cliente", $$v)},expression:"form.cliente"}})],1),_c('div',{staticClass:"form-group col-md-2"},[(!_vm.$v.form.$invalid)?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.create()}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e(),_c('button',{staticClass:"btn bg-navy",on:{"click":function($event){return _vm.seleccionarClientes()}}},[_c('i',{staticClass:"fas fa-list"})])])]):_c('div',{staticClass:"row justify-content-center"},[(_vm.listasForms.clientes.length == 0)?_c('div',{staticClass:"callout callout-warning text-muted col-md-6"},[_c('h5',{staticClass:"m-0"},[_vm._v("No hay clientes disponibles para ser asignados!")])]):_vm._e()])]):_vm._e(),(_vm.clientes)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.clientes),function(item){return _c('div',{key:item.id,staticClass:"col-lg-3 col-6 p-2 m-1"},[_c('div',{staticClass:"small-box bg-lightblue mb-0"},[_c('div',{staticClass:"inner"},[_c('h3',[_vm._v(_vm._s(item.cliente.n_identificacion))]),_c('p',[_vm._v(_vm._s(item.cliente.razon_social))])]),_vm._m(0,true),_c('div',{staticClass:"small-box-footer"},[_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-md-8 text-center"},[_vm._v(_vm._s(item.cliente.email ? item.cliente.email : "Sin email de contacto"))]),(_vm.$parent.subasta && _vm.$parent.subasta.estado == 1)?_c('div',{staticClass:"col-md-4 text-center"},[_c('a',{staticClass:"fas fa-minus-circle text-danger text-lg",on:{"click":function($event){return _vm.destroy(item.id)}}})]):_vm._e()])])])])}),0)]):_c('div',{staticClass:"card-body"},[_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-user-tie"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"callout callout-warning text-muted m-0"},[_c('h5',{staticClass:"m-0"},[_vm._v("No hay clientes asignados!")])])])
}]

export { render, staticRenderFns }